import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ISignin } from '../interfaces/authentication-data';
import { APIBaseURLOption } from '../interfaces/api-base-url.interface';
import { API_BASE_URL_TOKEN } from '../providers/api-base-url.provider';

@Injectable({
  providedIn: 'root',
})
export class SigninService {
  private readonly url!: string;
  public headers: { [key: string]: string } = {
    'Content-Type': 'application/json',
  };

  constructor(
    private http: HttpClient,
    @Inject(API_BASE_URL_TOKEN)
    private readonly apiBaseUrlProvider: APIBaseURLOption
  ) {
    this.url = `${this.apiBaseUrlProvider.rootBase}/user`;
  }

  public verifyUser(token: string) {
    return this.http.put(`${this.url}`, null, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      responseType: 'text',
    });
  }
}
