import { Component } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ScheduleService } from '../schedule.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-add-schedule',
  templateUrl: './add-schedule.component.html',
  styleUrls: ['./add-schedule.component.scss'],
})
export class AddScheduleComponent {
  public deviceId!: string;
  public timerForm!: FormGroup<any>;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly fb: FormBuilder,
    private readonly scheduleService: ScheduleService,
    private readonly snackbar: MatSnackBar,
    private readonly router: Router,
  ) {}

  ngOnInit() {
    this.deviceId = (this.activatedRoute.snapshot.params as any).deviceId;
    
    this.initializeForm();
  }

  public addSchedule() {
    const body = this.timerForm.getRawValue();
    const temp = ['0', '0', '0', '0', '0', '0', '0'];
    const dayValues = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

    for (const element of body.days) {
      const cur = element;

      temp[cur] = dayValues[cur];
    }

    body.days = temp;

    this.scheduleService.addSchedule(body).subscribe({
      next: () => {
        this.snackbar.open('Successfully added!', 'close', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          panelClass: ['bg-primary', 'text-white'],
        });
        this.router.navigate([
          '/',
          'board',
          'devices',
          'overview',
          this.deviceId,
        ]);
      },
      error: (error) => {
        console.error(`[addSchedule]`, error);

        this.snackbar.open('An error occurred!', 'close', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          panelClass: ['bg-danger', 'text-white'],
        });
      },
    });
  }

  public get controls() {
    return this.timerForm.controls as { [key: string]: any };
  }

  public initializeForm() {
    this.timerForm = this.fb.nonNullable.group({
      deviceId: new FormControl(this.deviceId, [Validators.required]),
      enabled: new FormControl(false, [Validators.required]),
      days: new FormControl([0, 1, 2, 3, 4, 5, 6], [Validators.required]),
      repeat: new FormControl(false, [Validators.required]),
      action: new FormControl(0, [Validators.required]),
      time: new FormControl(null, [Validators.required]),
    });
  }
}
