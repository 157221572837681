import { Injectable } from '@angular/core';
import { FirebaseAuthService } from './firebase-auth.service';

@Injectable({
  providedIn: 'root',
})
export class SignoutService {
  constructor(private readonly firebaseAuthService: FirebaseAuthService) {}

  public signout() {
    return this.firebaseAuthService.signOut();
  }
}
