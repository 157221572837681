import { Component } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IAddSchedule, ScheduleService } from '../schedule.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-edit-schedule',
  templateUrl: './edit-schedule.component.html',
  styleUrls: ['./edit-schedule.component.scss'],
})
export class EditScheduleComponent {
  public deviceId!: string;
  public id!: string;
  public timerForm!: FormGroup<any>;
  public schedule!: IAddSchedule;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly fb: FormBuilder,
    private readonly scheduleService: ScheduleService,
    private readonly snackbar: MatSnackBar,
    private readonly router: Router,
  ) {}

  ngOnInit() {
    this.deviceId = (this.activatedRoute.snapshot.params as any).deviceId;
    this.id = (this.activatedRoute.snapshot.params as any).scheduleId;

    this.getScheduleInformation();
  }

  public getScheduleInformation() {
    this.scheduleService.listSchedules(this.deviceId, this.id).subscribe({
      next: (schedules) => {
        this.schedule = { ...schedules[0], days: schedules[0].days.map((_, i): any => _ !== '0' ? i : undefined) };
        this.initializeForm();
      },
      error: (error) => {
        console.error(`[getScheduleInformation]`, error);

        this.snackbar.open('An error occurred!', 'close', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          panelClass: ['bg-danger', 'text-white'],
        });
      },
    });
  }

  public updateSchedule() {
    const body = this.timerForm.getRawValue();
    const temp = ['0', '0', '0', '0', '0', '0', '0'];
    const dayValues = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

    for (const element of body.days) {
      const cur = element;

      temp[cur] = dayValues[cur];
    }

    body.days = temp;

    this.scheduleService.updateSchedule(this.id, body).subscribe({
      next: () => {
        this.snackbar.open('Successfully edited!', 'close', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          panelClass: ['bg-primary', 'text-white'],
        });
        this.router.navigate([
          '/',
          'board',
          'devices',
          'overview',
          this.deviceId,
        ]);
      },
      error: (error) => {
        console.error(`[updateSchedule]`, error);

        this.snackbar.open('An error occurred!', 'close', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          panelClass: ['bg-danger', 'text-white'],
        });
      },
    });
  }

  public get controls() {
    return this.timerForm.controls as { [key: string]: any };
  }

  public initializeForm() {
    this.timerForm = this.fb.nonNullable.group({
      deviceId: new FormControl(this.schedule?.deviceId, [Validators.required]),
      enabled: new FormControl(this.schedule?.enabled, [Validators.required]),
      days: new FormControl(this.schedule?.days, [Validators.required]),
      repeat: new FormControl(this.schedule?.repeat, [Validators.required]),
      action: new FormControl(this.schedule?.action, [Validators.required]),
      time: new FormControl(this.schedule?.time, [Validators.required]),
    });
  }
}
