<div class="d-flex flex-column w-100">
    <button mat-button class="w-fit-content my-2 ps-3 pe-3 rounded-4">
        <mat-icon class="my-auto">arrow_back</mat-icon>
        <span class="ms-1 my-auto">back</span>
    </button>

    <!-- INFO Heading -->
    <h1 class="mt-5 mb-1 mat-h1 fw-bolder">Schedule new Control</h1>
    <p class="mb-0 mat-body-2">Schedule the day and time of day your devices should go off/on/toggled.</p>

    <form class="d-flex flex-column w-100 w-sm-50 w-md-75 w-lg-40 mt-4" [formGroup]="timerForm">
        <mat-slide-toggle formControlName="enabled" class="ms-auto">Enabled</mat-slide-toggle>

        <mat-form-field class="mt-3" appearance="outline">
            <mat-label>Action to be taken</mat-label>
            <mat-select formControlName="action">
                <mat-option
                    *ngFor="let item of [{name: 'ON', value: 1}, {name: 'OFF', value: 0}, {name: 'TOGGLE', value: 2}]"
                    [value]="item.value">
                    {{ item.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    
        <mat-form-field appearance="outline">
            <mat-label>Time of day</mat-label>
            <input matInput formControlName="time" type="time" min="0" max="11" placeholder="HH:MM">
        </mat-form-field>
    
        <mat-form-field appearance="outline">
            <mat-label>Days</mat-label>
            <mat-select formControlName="days" multiple>
                <mat-option
                    *ngFor="let item of [
                        {name: 'Sunday', index: 0}, 
                        {name: 'Monday', index: 1}, 
                        {name: 'Tuesday', index: 2}, 
                        {name: 'Wednesday', index: 3}, 
                        {name: 'Thursday', index: 4}, 
                        {name: 'Friday', index: 5}, 
                        {name: 'Saturday', index: 6}]"
                    [value]="item.index">{{ item.name }}</mat-option>
            </mat-select>
        </mat-form-field>
    
        <mat-checkbox formControlName="repeat">Make it continuous (repeats)?</mat-checkbox>

        <button mat-flat-button class="w-fit-content mt-3 rounded-3" color="primary"
            [disabled]="timerForm.invalid" (click)="addSchedule()">Save</button>
    </form>
</div>
