import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APIBaseURLOption } from './interfaces/api-base-url.interface';
import { API_BASE_URL_TOKEN } from './providers/api-base-url.provider';

export interface IAddSchedule {
  id?: string;
  deviceId?: string;
  enabled: boolean;
  days: string[];
  repeat: boolean;
  action: number;
  time: string;
}

@Injectable({
  providedIn: 'root',
})
export class ScheduleService {
  private readonly url!: string;
  public headers: { [key: string]: string } = {
    'Content-Type': 'application/json',
  };
  public baseUrl!: string;

  constructor(
    @Inject(API_BASE_URL_TOKEN)
    private readonly apiBaseUrlProvider: APIBaseURLOption,
    private readonly httpClient: HttpClient
  ) {
    this.url = `${this.apiBaseUrlProvider.rootBase}/device`;
  }

  public deleteSchedule(deviceId: string, id: string) {
    const url = `${this.url}/${deviceId}/schedule`;
    let params = new HttpParams();

    if (id) params = params.append('id', id);

    return this.httpClient.delete<void>(url, {
      params,
      headers: this.headers,
    });
  }

  public updateSchedule(id: string, changes: IAddSchedule) {
    const url = `${this.url}/${changes.deviceId}/schedule/${id}`;

    return this.httpClient.patch<void>(url, changes, {
      headers: this.headers,
    });
  }

  public listSchedules(deviceId: string, id?: string) {
    const url = `${this.url}/${deviceId}/schedule`;
    let params = new HttpParams();

    if (id) params = params.append('id', id);

    return this.httpClient.get<IAddSchedule[]>(url, {
      params,
      headers: this.headers,
    });
  }

  public addSchedule(data: IAddSchedule) {
    const url = `${this.url}/${data.deviceId}/schedule`;

    return this.httpClient.post(url, data, {
      headers: this.headers,
      observe: 'body',
      responseType: 'text',
    });
  }
}
