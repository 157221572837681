import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionService } from '../services/session.service';

@Injectable()
export class AddTokenInterceptor implements HttpInterceptor {
  constructor(private readonly sessionService: SessionService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const reqClone = request.clone({
      setHeaders: { Authorization: `Bearer ${this.sessionService.token}` },
    });

    return request.method.toLowerCase() == 'put' &&
      request.url.split('/').includes('user')
      ? next.handle(request)
      : next.handle(reqClone);
  }
}
